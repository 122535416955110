import {
  BlockedUser,
  Brand,
  Challenge,
  ChallengeTemplate,
  Connection,
  Score,
  ShopifyDiscountOrder,
} from '@kalecard/common';
import { gql } from '../__generated__';
import { ChallengeRequest, CommentsAnalysis } from '../__generated__/graphql';

export const BRAND_USER = gql(`
  query BrandUser($userId: ID!) {
    brandUser(userId: $userId) {
      id
      name
      email
      role
      administeredBrands {
        brand {
          id
          name
          logoUrl
        }
        role
      }
      companyTitle
    }
  }
`);

export interface BrandData {
  brand: Brand;
}

export const BRAND = gql(`
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      name
      hasWhitelistPermission
      contentBudget
      isKaleStoreEnabled
      platformFee
      contentBudgetThreshold
      referralCode
      contentBudgetMax
      socialHandle
      tiktokSocialHandle
      youtubeSocialHandle
      snapchatSocialHandle
      pinterestSocialHandle
      logoUrl
      admins {
        id
        name
        email
      }
      brandCategories {
        id
        name
      }
      isChallengeReviewRequired
      shopifyDomain
      challengesPaused
      shouldShowOrders
      autoRechargeEnabled
      coverUrl
      platformFeePoNumber
      contentBudgetPoNumber
      storeLocatorUrl
      isGeoFenced
      paymentMethods {
        id
        name
        type
        lastFourDigits
        expirationMonth
        expirationYear
        createdAt
        isDefault
      }
      isBrandSideSetupComplete
      isSelfService
      shouldRequireCommercialSound
      enableWireTransferInvoicePayment
    }
  }
`);

export const CONTENT = gql(`
  query Content(
    $brandId: ID!
    $first: Int
    $after: String
    $challengeIds: [ID]
    $startDate: String
    $endDate: String,
    $creatorHandle: String
  ) {
    content(
      brandId: $brandId
      first: $first
      after: $after
      challengeIds: $challengeIds
      startDate: $startDate
      endDate: $endDate
      creatorHandle: $creatorHandle
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          externalPermalink
          likeCount
          commentCount
          impressions
          productType
          externalPlatform
          externalUser {
            id
            externalPlatform
            username
            externalUrl
          }
          caption
          mediaUrls {
            url
            mediaType
            carouselItem {
              id
            }
            isRecommended
          }
          siblings {
            id
            externalPermalink
            likeCount
            commentCount
            impressions
            externalPlatform
            caption
          }
          collections(brandId: $brandId) {
            id
          }
          deal {
            id
            creator {
              id
            }
          }
        }
      }
    }
  }
`);

export const WHITELIST_CONTENT = gql(`
  query WhitelistContent(
    $brandId: ID!
    $states: [String]
    $creatorId: String
    $first: Int
    $after: String
  ) {
    whitelistContent(
      brandId: $brandId
      states: $states
      creatorId: $creatorId
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          budget
          state
          post {
            id
            externalPermalink
            externalUser {
              id
              externalPlatform
              username
              externalUrl
            }
            likeCount
            commentCount
            impressions
            caption
            mediaUrls {
              url
              mediaType
            }
            siblings {
              id
              externalPermalink
              likeCount
              commentCount
              impressions
              externalPlatform
              caption
            }
          }
        }
      }
    }
  }
`);

export const BRAND_TRANSACTIONS = gql(`
  query BrandTransactions($brandId: ID!, $first: Int, $after: String) {
    brandTransactions(brandId: $brandId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          amount
          type
          paymentMethodType
          paymentStatus
          description
          invoice {
            id
            url
            status
            sentAt
          }
        }
      }
    }
  }
`);

export const BRAND_CREATORS_DATA = gql(`
  query CreatorsData($brandId: ID!) {
    creatorsData(brandId: $brandId) {
      creators
      followership
      activatedCreators
      activatedFollowership
      referralCodeBreakdown {
        referralCode
        count
        activatedCount
      }
    }
  }
`);

export interface BrandOrdersDataQuery {
  brandOrders: Connection<ShopifyDiscountOrder>;
}

export const BRAND_ORDERS = gql(`
  query BrandOrders(
    $brandId: ID!
    $first: Int
    $after: String
    $before: String
  ) {
    brandOrders(
      brandId: $brandId
      first: $first
      after: $after
      before: $before
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          externalOrderId
          createdAt
          totalLineItemsPriceAmount
          creatorTransactions {
            createdAt
            amount
            type
            creator {
              externalUsers {
                id
                username
                externalPlatform
              }
            }
          }
          creatorPendingTransactions {
            createdAt
            amount
            type
            creator {
              externalUsers {
                id
                username
                externalPlatform
              }
            }
          }
          kaleOwesAmount
          brandOwesAmount
          shopifyDiscountCode {
            creator {
              externalUsers {
                id
                username
                externalPlatform
              }
            }
          }
        }
      }
    }
  }
`);

export const BRAND_ORDER_METRICS = gql(`
  query BrandOrderMetrics($brandId: ID!, $after: String, $before: String) {
    brandOrderMetrics(brandId: $brandId, after: $after, before: $before) {
      kaleOwesAmount
      brandOwesAmount
      totalSpend
      numOrders
    }
  }
`);

export const BRAND_THREADS = gql(`
  query BrandThreads(
    $first: Int
    $after: String
    $facebookAccountId: ID!
    $brandId: ID!
    $instagramId: ID
  ) {
    brandThreads(
      first: $first
      after: $after
      facebookAccountId: $facebookAccountId
      brandId: $brandId
      instagramId: $instagramId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          participants {
            id
            username
          }
          messages {
            id
            text
            from
            createdAt
            storyUrl
          }
        }
      }
    }
  }
`);

export const CHALLENGE = gql(`
  query Challenge($challengeId: ID!, $brandId: ID!) {
    challenge(challengeId: $challengeId, brandId: $brandId) {
      id
      description
      tagRequirements
      totalImpressions
      state
      totalPosts
      firstActivationDate
    }
  }
`);

export interface PostScoresData {
  postScores?: Score[];
}

export const POST_SCORES = gql(`
  query PostScores($brandId: ID, $postId: ID, $fetchAll: Boolean) {
    postScores(brandId: $brandId, postId: $postId, fetchAll: $fetchAll) {
      id
      description
      inverseDescription
      isSelected
      helpText
      points
      isActive
    }
  }
`);

export interface WhitelistBadges {
  recommended?: number;
  brandAccepted?: number;
  creatorAccepted?: number;
  inReview?: number;
  ready?: number;
  inProgress?: number;
  completed?: number;
}

export interface ChallengesBadges {
  pendingReviewFromBrand?: number;
  trendsPendingReviewFromBrand?: number;
}

export interface Badges {
  whitelistBadges?: WhitelistBadges;
  challengesBadges?: ChallengesBadges;
}

export const BRAND_BADGES = gql(`
  query BrandBadges($brandId: ID!) {
    brandBadges(brandId: $brandId) {
      whitelistBadges {
        recommended
        brandAccepted
        creatorAccepted
        inReview
        ready
        inProgress
        completed
      }
      challengesBadges {
        pendingReviewFromBrand
        trendsPendingReviewFromBrand
      }
    }
  }
`);

export const BRAND_LANDING_PAGE = gql(`
  query BrandLandingPage($referralCode: String!) {
    brandLandingPage(referralCode: $referralCode) {
      title
      videos {
        url
        username
        externalUrl
      }
    }
  }
`);

export interface ChallengesListInterface {
  challengesList: Connection<Challenge>;
}

export const SIMPLE_CHALLENGES_LIST = gql(`
  query ChallengesList(
    $first: Int
    $after: String
    $brandId: ID!
    $onlyCompleted: Boolean
    $onlyActive: Boolean
  ) {
    challengesList(
      first: $first
      after: $after
      brandId: $brandId
      onlyCompleted: $onlyCompleted
      onlyActive: $onlyActive
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          description
          tagRequirements
          state
          startDate
          endDate
          totalImpressions
          totalPosts
          firstActivationDate
          createdAt
          exampleUrl
          challengeTemplate {
            id
            title
            template
          }
          isCommercialSoundsOnly
          feedback {
            id
            feedback
            status
          }
          postRequirements {
            id
            requirement
            emoji
            iconFilename
            type
          }
          totalClaims
        }
      }
    }
  }
`);

export interface PendingChallengesInterface {
  pendingChallenges: Challenge[];
}

export const PENDING_CHALLENGES = gql(`
  query PendingChallenges($brandId: ID!) {
    pendingChallenges(brandId: $brandId) {
      id
      description
      tagRequirements
      state
      startDate
      endDate
      createdAt
      exampleUrl
      isCommercialSoundsOnly
      postRequirements {
        id
        requirement
        emoji
        iconFilename
        type
      }
    }
  }
`);

export const CHALLENGE_SUGGESTIONS = gql(`
  query ChallengeSuggestions($brandId: ID!) {
    challengeSuggestions(brandId: $brandId) {
      id
      description
      tagRequirements
      state
      createdAt
      startDate
      firstActivationDate
      endDate
      exampleUrl
      feedback {
        id
        createdAt
        feedback
        actorName
        status
      }
      challengeTemplate {
        id
        title
        template
        soundUrl
        exampleUrl
        templateType
      }
    }
  }
`);

export const BLOCKED_USERS = gql(`
  query BlockedUsers($brandId: ID!) {
    blockedUsers(brandId: $brandId) {
      username
      externalPlatform
      externalUrl
    }
  }
`);

export const GET_STRIPE_HOSTED_INVOICE_URL = gql(`
  query GetStripeHostedInvoiceUrl($brandId: ID!, $brandTransactionId: ID!) {
    getStripeHostedInvoiceUrl(
      brandId: $brandId
      brandTransactionId: $brandTransactionId
    )
  }
`);

export const BRAND_ADMINS = gql(`
  query BrandAdmins($brandId: ID!) {
    brandAdmins(brandId: $brandId) {
      id
      name
      email
      createdAt
    }
  }
`);
export const BRAND_CATEGORIES = gql(`
  query BrandCategories($first: Int, $after: String) {
    brandCategories(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`);

export const FACEBOOK_BUSINESSES = gql(`
  query FacebookBusinesses {
    facebookBusinesses {
      id
      name
      link
      profilePictureUri
    }
  }
`);

export const POST_COLLECTIONS = gql(`
  query PostCollections($brandId: ID!, $first: Int, $after: String) {
    postCollections(brandId: $brandId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`);

export const POST_COLLECTION = gql(`
  query PostCollection(
    $brandId: ID!
    $collectionId: ID!
    $first: Int
    $after: String
  ) {
    postCollection(brandId: $brandId, collectionId: $collectionId) {
      id
      title
      posts(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            externalPermalink
            likeCount
            commentCount
            impressions
            productType
            externalPlatform
            externalUser {
              id
              externalPlatform
              username
              externalUrl
            }
            caption
            mediaUrls {
              url
              mediaType
              carouselItem {
                id
              }
              isRecommended
            }
            siblings {
              id
              externalPermalink
              likeCount
              commentCount
              impressions
              externalPlatform
              caption
            }
            collections(brandId: $brandId) {
              id
              title
            }
          }
        }
      }
    }
  }
`);

export const GET_NEXT_BONSAI_TASK = gql(`
  query GetNextTask($queueName: String!) {
    getNextTask(queueName: $queueName) {
      taskId
      metadata
      remainingTasksInQueue
    }
  }
`);

export interface BrandReport {
  dashboardUrl: string;
}

export interface BrandReportInterface {
  brandReport: BrandReport;
}

export const BRAND_REPORT = gql(`
  query BrandReport($brandId: ID!, $challengeId: ID) {
    brandReport(brandId: $brandId, challengeId: $challengeId) {
      dashboardUrl
    }
  }
`);

export const INITIATE_CONTENT_BUDGET_CHECKOUT = gql(`
  query InitiateContentBudgetCheckout($brandId: ID!) {
    initiateContentBudgetCheckout(brandId: $brandId) {
      clientSecret
    }
  }
`);

export const INITIATE_CUSTOMER_PORTAL = gql(`
  query InitiateCustomerPortal($brandId: ID!) {
    initiateCustomerPortal(brandId: $brandId) {
        url
    }
  }
`);

export const BRAND_INFLUENCER_POSTS = gql(`
  query BrandInfluencerPosts(
    $brandId: ID!
    $first: Int
    $after: String
  ) {
    brandInfluencerPosts(
      brandId: $brandId
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          state
          postUrl
          post {
            id
            externalPermalink
            likeCount
            commentCount
            impressions
            productType
            externalPlatform
            caption
            externalUser {
              id
              externalPlatform
              username
              externalUrl
            }
            mediaUrls {
              url
              mediaType
              carouselItem {
                id
              }
              isRecommended
            }
          }
        }
      }
    }
  }
`);

export const REFRESH_PAYMENT_METHODS = gql(`
  query RefreshPaymentMethods($id: ID!) {
    brand(id: $id) {
      id
      paymentMethods {
        id
        name
        type
        lastFourDigits
        expirationMonth
        expirationYear
        createdAt
        isDefault
      }
    }
  }
`);

export interface PendingChallengeRequestsData {
  pendingChallengeRequests: ChallengeRequest[];
}

export const PENDING_CHALLENGE_REQUESTS = gql(`
  query PendingChallengeRequests($brandId: ID!, $source: String!, $templateType: String) {
    pendingChallengeRequests(brandId: $brandId, source: $source, templateType: $templateType) {
      id
      source
      note
      description
      startDate
      endDate
      difficulty
      title
      exampleUrl
      challengeTemplate {
        id
        title
        template
        soundUrl
        exampleUrl
        templateType
        tags {
          id
          name
        }
      }
      createdAt
      status
    }
  }  
`);

export interface CommentsAnalysisData {
  commentsAnalysis: CommentsAnalysis[];
}

export const COMMENTS_ANALYSIS = gql(`
  query CommentsAnalysis($brandId: ID!) {
    commentsAnalysis(brandId: $brandId) {
      id
      summary
      tags {
        name
        comments {
          text
          postUrl
        }
        isPositive
      }
      date
    }
  }  
`);

export const BRAND_PHOTOS = gql(`
  query BrandPhotos($brandId: ID!, $showPurchased: Boolean, $first: Int, $after: String, $width: Int) {
    brandPhotos(brandId: $brandId, showPurchased: $showPurchased, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          mediaUrl(width: $width) {
            url
            mediaType
          }
          deal {
            id
            updatedAt
            creator {
              id
              name
              externalUsers {
                id
                username
                externalPlatform
              }
            }
          }
        }
      }
    }
  }
`);

export const BRAND_PHOTO = gql(`
  query BrandPhoto($brandId: ID!, $photoId: ID!, $width: Int) {
    brandPhoto(brandId: $brandId, photoId: $photoId) {
      id
      createdAt
      mediaUrl(width: $width) {
        url
        mediaType
      }
      deal {
        id
        updatedAt
        creator {
          id
          name
          externalUsers {
            id
            username
            externalPlatform
          }
        }
      }
    }
  }
`);

export const INITIATE_PHOTO_PURCHASE_CHECKOUT = gql(`
  query InitiatePhotoPurchaseCheckout($brandId: ID!, $photoIds: [ID!]!) {
    initiatePhotoPurchaseCheckout(brandId: $brandId, photoIds: $photoIds) {
      clientSecret
    }
  }
`);

export interface ChallengeTemplatesInterface {
  challengeTemplates: ChallengeTemplate[];
}

export const CHALLENGE_TEMPLATES = gql(`
  query ChallengeTemplates($brandId: ID, $templateType: String, $status: String) {
    challengeTemplates(brandId: $brandId, templateType: $templateType, status: $status) {
      id
      title
      template
      brandCategories {
        id
        name
      }
    }
  }
`);

export const GET_DOWNLOAD_SIGNED_URL = gql(`
   query GetDownloadSignedUrl($id: String!, $mimeType: String!, $type: SignedUrlType!) {
     getDownloadSignedUrl(id: $id, mimeType: $mimeType, type: $type)
   }
`);

export const EXTERNAL_USERS = gql(`
  query ExternalUsers($brandId: ID) {
    externalUsers(brandId: $brandId) {
      id
      username
      externalPlatform
      pictureUrl
      externalId
    }
  }
`);

export const SIMPLE_EXTERNAL_POSTS = gql(`
  query SimpleExternalPosts($externalUserId: ID!, $first: Int, $after: String) {
    externalPosts(externalUserId: $externalUserId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          caption
          createdAt
          commentCount
          likeCount
          reach
          externalPermalink
          mediaType
          mediaUrl
        }
      }
    }
  }
`);
