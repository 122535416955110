import { ArrowDownTrayIcon, MegaphoneIcon } from "@heroicons/react/24/solid";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as FilledBookmarkIcon } from "@heroicons/react/24/solid";
import { ActionType, logAction, Modal, Post } from "@kalecard/common";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { UserContext } from "../../../providers/UserProvider";
import ContentWhitelistRequestForm from "../../forms/ContentWhitelistRequestForm";
import WhitelistAccessRequestForm from "../../forms/WhitelistAccessRequestForm";
import MediaComponent from "../../MediaComponent";
import PostCaptionStats from "../../utils/PostCaptionStats";
import { classNames } from "../../../utils/style";
import PostCollectionsEditorForm from "../../forms/PostCollectionsEditorForm";
import { useFragment, useMutation } from "@apollo/client";
import { COLLECTIONS_OF_POST_FRAGMENT } from "../../../graphql/fragments";
import { DOWNLOAD_POST } from "../../../graphql/mutations";

interface ContentListComponentInterface {
  post: Post;
  onContentWhitelistSubmit?: () => void;
}

export default function ContentListComponent({
  post,
  onContentWhitelistSubmit,
}: ContentListComponentInterface) {
  const { brandId, brandRecord } = useContext(UserContext);
  const [
    contentWhitelistRequestModalOpen,
    setContentWhitelistRequestModalOpen,
  ] = useState(false);
  const [whitelistAccessRequestModalOpen, setWhitelistAccessRequestModalOpen] =
    useState(false);
  const [postCollectionModalOpen, setPostCollectionModalOpen] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState(null);
  const history = useHistory();
  const [downloadPost] = useMutation(DOWNLOAD_POST);

  const handleDownload = (url: string, post: Post) => {
    logAction(ActionType.CLICK, "ContentPage", {
      action_name: "download_post",
      post_id: post.id,
      deal_id: post.deal?.id,
      creator_id: post.deal?.creator?.id,
      external_user: post.externalUser?.id,
      media_url: url,
      brand_id: brandId
    });

    window.open(url, "_blank");

    try {
      downloadPost({
        variables: {
          postId: post.id,
          brandId: brandId
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleModalSubmit = () => {
    onContentWhitelistSubmit();
    setContentWhitelistRequestModalOpen(false);
    history.push("/whitelisting");
  };

  const handleWhitelistButtonClick = (mediaUrl) => {
    setSelectedMediaUrl(mediaUrl);
    const isWhitelistingPermitted =
      brandRecord?.hasWhitelistPermission === true;
    logAction(ActionType.CLICK, "ContentPage", {
      action_name: isWhitelistingPermitted
        ? "content_whitelist_request"
        : "whitelist_access_request",
    });
    if (isWhitelistingPermitted) {
      setContentWhitelistRequestModalOpen(true);
    } else {
      setWhitelistAccessRequestModalOpen(true);
    }
  };

  const { data: postCollectionData } = useFragment({
    fragment: COLLECTIONS_OF_POST_FRAGMENT,
    from: {
      __typename: "Post",
      id: post.id,
    },
    variables: {
      brandId: brandId,
    },
  });

  return (
    <>
      {post.mediaUrls?.map((mediaUrl) => {
        return (
          <li
            key={mediaUrl.url}
            className="mb-2 flex divide-y divide-gray-200 rounded-lg bg-white text-center shadow sm:mr-2"
          >
            <div className="flex h-full flex-col justify-between space-y-1 p-2">
              <MediaComponent
                key={mediaUrl.url}
                url={mediaUrl.url}
                mediaType={mediaUrl.mediaType}
                shouldChangeColor={false}
              />
              <PostCaptionStats post={post} />
              <div className="flex justify-between pt-1">
                <div className="flex">
                  <button
                    onClick={() => handleWhitelistButtonClick(mediaUrl)}
                    className="place-self-end rounded-br-lg border border-transparent"
                  >
                    <MegaphoneIcon
                      className={classNames(
                        brandRecord?.hasWhitelistPermission === true
                          ? "text-black-500"
                          : "text-gray-400",
                        "h-4 w-4 hover:opacity-50"
                      )}
                    />
                  </button>
                </div>
                <div className="flex">
                  <button
                    onClick={() => handleDownload(mediaUrl.url, post)}
                    className="rounded-br-lg border border-transparent"
                  >
                    <ArrowDownTrayIcon
                      className="text-black-500 h-4 w-4 hover:opacity-50"
                      aria-hidden="true"
                    />
                  </button>
                  <button
                    className="ml-1 rounded-br-lg border border-transparent"
                    onClick={() => setPostCollectionModalOpen(true)}
                  >
                    {postCollectionData?.collections.length > 0 ? (
                      <FilledBookmarkIcon
                        className="text-black-600 h-4 w-4 hover:opacity-50"
                        aria-hidden="true"
                      />
                    ) : (
                      <BookmarkIcon
                        className="text-black-600 h-4 w-4 hover:opacity-50"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </li>
        );
      })}
      <Modal
        isModalOpen={whitelistAccessRequestModalOpen}
        onClose={() => setWhitelistAccessRequestModalOpen(false)}
      >
        <WhitelistAccessRequestForm brandId={brandId} />
      </Modal>
      <Modal
        isModalOpen={contentWhitelistRequestModalOpen}
        onClose={() => setContentWhitelistRequestModalOpen(false)}
      >
        <ContentWhitelistRequestForm
          post={post}
          brandId={brandId}
          mediaUrl={selectedMediaUrl}
          onSubmit={handleModalSubmit}
        />
      </Modal>
      <Modal
        className="w-full max-w-xl"
        isModalOpen={postCollectionModalOpen}
        onClose={() => {
          setPostCollectionModalOpen(false);
        }}
      >
        <PostCollectionsEditorForm
          postId={post.id}
          closeModal={() => setPostCollectionModalOpen(false)}
        />
      </Modal>
    </>
  );
}
