import { Brand, Spinner, ChallengeTemplate } from '@kalecard/common';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_SUGGESTED_CHALLENGE } from '../../graphql/mutations';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  CHALLENGE_SUGGESTIONS,
  CHALLENGE_TEMPLATES,
  ChallengeTemplatesInterface,
} from '../../graphql/queries';
import { Challenge } from '../../__generated__/graphql';
import { ChallengeThemes, SuggestionNote } from './SuggestChallengeForm';

interface UpdateSuggestedChallengeFormInterface {
  brand: Brand;
  challenge: Challenge;
  onSubmit?: (suggestedChallenge: Challenge) => void;
  onClose?: () => void;
}

type UpdateSuggestedChallengeFormInputs = {
  exampleUrl: string;
};

export default function UpdateSuggestedChallengeForm({
  brand,
  challenge,
  onSubmit,
  onClose,
}: UpdateSuggestedChallengeFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date>(
    challenge.startDate ? new Date(Number(challenge.startDate)) : null
  );
  const [endDate, setEndDate] = useState<Date>(
    challenge.endDate ? new Date(Number(challenge.endDate)) : null
  );

  const [selectedTemplate, setSelectedTemplate] =
    useState<ChallengeTemplate | null>(challenge?.challengeTemplate);

  const date = new Date();

  const { reset, register, handleSubmit } =
    useForm<UpdateSuggestedChallengeFormInputs>({
      defaultValues: {
        exampleUrl: challenge.exampleUrl,
      },
    });

  const [note, setNote] = useState((challenge.feedback && challenge.feedback[0]?.feedback) ?? '');

  const [updateSuggestedChallenge] = useMutation(UPDATE_SUGGESTED_CHALLENGE);

  const { data: challengeTemplatesData } = useQuery<ChallengeTemplatesInterface>(CHALLENGE_TEMPLATES, {
    variables: {
      brandId: brand.id,
      templateType: "THEME",
      status: "ACTIVE"
    },
    skip: brand.brandCategories.length === 0,
  });



  const submit: SubmitHandler<UpdateSuggestedChallengeFormInputs> = async (
    data: UpdateSuggestedChallengeFormInputs
  ) => {
    if (note?.length > 100) {
      setError("The additional details section cannot contain more than 100 characters.");
      return;
    }

    setIsLoading(true);
    try {
      const result = await updateSuggestedChallenge({
        variables: {
          brandId: brand.id,
          templateId: selectedTemplate?.id,
          challengeId: challenge.id,
          exampleUrl: data.exampleUrl,
          startDate: startDate?.getTime()?.toString(),
          endDate: endDate?.getTime()?.toString(),
          note: note,
        },
        refetchQueries: [CHALLENGE_SUGGESTIONS],
      });
      console.log(result);
      onSubmit(result.data.suggestChallenge);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    try {
      const result = await updateSuggestedChallenge({
        variables: {
          brandId: brand.id,
          challengeId: challenge.id,
          state: 'DELETED',
        },
        refetchQueries: [CHALLENGE_SUGGESTIONS],
      });
      console.log(result);
      onClose();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="w-full space-y-2">
      <div className="flex flex-col space-y-2">
        {/* Template Pick */}
        <label
          htmlFor="challengeTemplate"
          className="block text-sm font-medium text-gray-700"
        >
          Select a challenge theme:
        </label>
        {challengeTemplatesData?.challengeTemplates && (
          <ChallengeThemes
            challengeTemplates={challengeTemplatesData.challengeTemplates}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        {/* Note */}
        <SuggestionNote onChange={setNote} defaultNote={note} />
        {/* Example Url */}
        <div className="mt-2">
          <label
            htmlFor="exampleUrl"
            className="block text-sm font-medium text-gray-700"
          >
            Example Url:
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              {...register('exampleUrl')}
              name="exampleUrl"
              id="exampleUrl"
              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        {/* Dates */}
        <div className="flex flex-col space-y-2">
          {/* Start Date */}
          <div>
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-700"
            >
              Set an activation date for this challenge.
            </label>
            <DatePicker
              className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
              selected={startDate}
              showTimeSelect
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy HH:mm"
              minDate={new Date().setDate(date.getDate() + 1)}
            />
          </div>
          {/* End Date */}
          <div>
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-700"
            >
              Set a deactivation date for this challenge.
            </label>
            <DatePicker
              className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
              selected={endDate}
              showTimeSelect
              onChange={(date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy HH:mm"
              minDate={
                startDate
                  ? new Date(startDate)
                  : new Date().setDate(date.getDate() + 1)
              }
            />
          </div>
        </div>

        <div className="flex justify-between pt-5">
          <div>
            <button
              type="button"
              onClick={onDelete}
              disabled={isLoading}
              className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
            >
              Delete
            </button>
          </div>
          <div className="flex space-x-2">
            {isLoading && (
              <div className="flex flex-wrap content-center justify-center">
                <Spinner size="h-6 w-6" />
              </div>
            )}
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handleSubmit(submit)}
                disabled={isLoading || !selectedTemplate}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
              >
                Update
              </button>
            </div>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </div>
    </form>
  );
}
