import { useEffect, useState } from 'react';

export default function TikTokEmbededVideo({
  url,
  width,
}: {
  url: string;
  width: number;
}) {
  const [embedHtml, setEmbedHtml] = useState<string | null>(null);

  useEffect(() => {
    async function fetchEmbedData() {
      try {
        const response = await fetch(
          `https://www.tiktok.com/oembed?url=${encodeURIComponent(url)}`
        );
        const data = await response.json();
        if (data && data.html) {
          setEmbedHtml(data.html);
        }
      } catch (error) {
        console.error('Error fetching TikTok embed data:', error);
      }
    }

    fetchEmbedData();
  }, [url]);

  // We need to add the TikTok embed script to the page
  // so that the embeded video will work
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    // This is the cleanup function that will run when the component is unmounted
    // or if this effect is run again
    return () => {
      document.body.removeChild(script);
    };
  }, [embedHtml]);

  return (
    <div style={{ width, overflow: 'hidden' }}>
      {embedHtml ? (
        <div dangerouslySetInnerHTML={{ __html: embedHtml }} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
